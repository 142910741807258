<!-- 模块说明 -->
<template>
  <div class="container">
    <div class="banner">
      <div class="banner-view fadeInLeft">
        <div class="view">
          <div class="title">数字员工解决方案</div>
          <div class="henxin"></div>
          <div class="text">
            在传统人力资源业务的基础上，晟工数智为企业提供高效的数字员工解决方案。
            随着社会经济的发展，晟工数智针对企业人力成本负担加重、劳动密集型产业“用工荒”、繁琐重复工作导致的人工效率低下等痛点，推出了涵盖数字员工、企业SaaS系统等数字人力资源产品，通过技术提升企业的整体运营效率，降低人力成本，助力企业高效完成数字化转型和商业创新。
          </div>
          <div @click="jumpTo" class="dazzle-button">
            <div class="button-text">获取数字员工</div>
          </div>
        </div>
      </div>
      <div class="decoration"></div>
    </div>
    <div class="modular modular-1">
      <img src="../../images/home/technology/p16.png" alt="" />
    </div>
    <div class="modular modular-2">
      <div class="title-on">
        <div class="t1">数字员工应用价值</div>
        <div class="t2">技术与业务双向融合创造更多价值</div>
        <div class="t3"></div>
      </div>
      <div class="content flex">
        <div v-for="(item, index) in projectList" :key="index" class="list">
          <div class="top">
            <el-image :src="item.img" fit="cover"></el-image>
          </div>
          <div class="bottom">
            <div class="name">{{ item.name }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modular modular-3">
      <div class="title-on">
        <div class="t1">应用场景</div>
        <div class="t2">Application Scenario</div>
        <div class="t3"></div>
      </div>
      <div class="content">
        <div class="changeList flex">
          <div
            v-for="(item, index) in changeList"
            :key="index"
            :class="{ on: changeOn == index }"
            @click="changeOn = index"
            class="list"
          >
            <img :src="item.icon" :alt="item.name" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="show-view">
          <img
            :src="changeList[changeOn].back"
            :alt="changeList[changeOn].name"
          />
          <div class="tips fadeInLeft" id="tips">
            <div class="name">{{ changeList[changeOn].name }}</div>
            <div class="text">{{ changeList[changeOn].text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modular modular-4">
      <div class="title-on">
        <div class="t1">服务优势</div>
        <div class="t2">Service advantages</div>
        <div class="t3"></div>
      </div>
      <div class="content flex">
        <div class="list" v-for="(item, index) in advantageList" :key="index">
          <img class="back" :src="item.back" :alt="item.name" />
          <div class="tips">
            <img :src="item.icon" :alt="item.name" />
            <div class="name">{{ item.name }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "数字员工|AI数字员工解决方案-海恒科技集团",
  },
  components: {},
  data() {
    return {
      projectList: [
        {
          name: "颠覆传统用人模式，大幅降低企业成本",
          img: require("../../images/home/technology/p11.png"),
          text: "数字员工承担大量重复性工作，降低人力成本，同时大幅降低人工失误损失；降低运营成本，提升跨部门沟通效率与实现流程清晰顺畅。",
        },
        {
          name: "数字化转型核心抓手，助力企业生产经营，提质增效",
          img: require("../../images/home/technology/p12.png"),
          text: "数字员工在各领域将得到愈加广泛的应用，为企业数字化转型发展提供了新的路径，并将发挥越来越重要的作用，有助于企业生产经营提质增效。",
        },
        {
          name: "激发员工活力，让人回归人的价值",
          img: require("../../images/home/technology/p13.png"),
          text: "解绑时效性工作，让员工去做创造性工作，激发员工创意、创新、创造的价值与潜力，更好服务企业成长。",
        },
      ],
      changeList: [
        {
          name: "带货主播",
          icon: require("../../images/home/technology/p17.png"),
          text: "虚拟带货主播，支持定制/系统人物模板选择，为商家品牌、MCN 机构、广告营销、电子商务等提供人物形象、直播场景、自动直播等，节约企业人力成本，提高转化。",
          back: require("../../images/home/technology/p6.png"),
        },
        {
          name: "培训师",
          icon: require("../../images/home/technology/p19.png"),
          text: "数字培训师作为智能辅助教学工具，代替老师讲解重复性较高的内容，帮助教师更好地进行教学，同时提供在线互动和交流平台，让师生之间交流更加便捷和高效。",
          back: require("../../images/home/technology/p9.png"),
        },
        {
          name: "导游、讲解员",
          icon: require("../../images/home/technology/p18.png"),
          text: "展厅展馆、展会、主题乐园、博物馆等场所，可使用精通多国语言的虚拟导游/讲解员来进行内容解答和讲解。",
          back: require("../../images/home/technology/p7.png"),
        },
        {
          name: "智能客服",
          icon: require("../../images/home/technology/p20.png"),
          text: "通过地铁客服、银行智能客服等角色，实现智能高效的人性化服务。",
          back: require("../../images/home/technology/p14.png"),
        },
        {
          name: "智能文员",
          icon: require("../../images/home/technology/p21.png"),
          text: "流程固定、重复性高、耗人力的岗位，都可以使用数字员工，可实现不增加现有人员基础上，实现效率倍数提升。",
          back: require("../../images/home/technology/p15.png"),
        },
      ],
      changeOn: 0,
      advantageList: [
        {
          name: "全面的产品方案",
          icon: require("../../images/home/technology/p1.png"),
          text: "提供多种数字员工产品，对应提供完善的软件方案，针对不同的工种和需求，快速灵活引入数字员工能力。",
          back: require("../../images/home/technology/p4.png"),
        },
        {
          name: "强大的产品能力",
          icon: require("../../images/home/technology/p2.png"),
          text: "产品结合机器学习、计算机视觉等AI核心技术，可支持PC、移动、大屏等各种环境下跨平台部署，具备强大的二次开发空间，操作简单且提供免费教程和使用手册。",
          back: require("../../images/home/technology/p5.png"),
        },
        {
          name: "国内实时服务",
          icon: require("../../images/home/technology/p3.png"),
          text: "国内开发，提供多语言（中、英等）本地化支持，响应迅速。",
          back: require("../../images/home/technology/p10.png"),
        },
      ],
    };
  },
  watch: {
    changeOn(Value) {
      console.log(Value);
      let id = document.getElementById("tips");
      id.classList.remove("fadeInLeft");
      setTimeout(() => {
        id.classList.add("fadeInLeft");
      }, 100);
    },
  },
  created() {},
  methods: {
    jumpTo(){
      window.open('https://shenggongshuzhi.com/conduct', "_blank");
    }
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.modular-4 {
  .list:hover {
    .tips {
      .text {
        height: auto;
      }
      background: rgba(22, 109, 186, 0.8);
      transition: all 1s;
    }
  }
  .list {
    .tips {
      .text {
        width: 269px;
        height: 0;
        font-size: 18px;
        line-height: 26px;
        overflow: hidden;
      }
      .name {
        font-size: 24px;
        font-weight: bold;
        margin: 20px auto 30px;
      }
      img {
        width: 60px;
        height: 60px;
      }
      color: white;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .back {
      width: 100%;
      height: 100%;
    }
    width: 350px;
    height: 440px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  margin-bottom: 70px;
}
.modular-3 {
  .show-view {
    .tips {
      .text {
        width: 575px;
        font-size: 20px;
        line-height: 30px;
      }
      .name {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      color: white;
      position: absolute;
      top: 97px;
      left: 101px;
      opacity: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
    width: 100%;
    height: 360px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-top: 36px;
  }
  .changeList {
    .on {
      img {
        filter: grayscale(0) !important;
      }
      color: #166dba !important;
      background: #f0f8ff;
      border-bottom: 2px solid #0c7fe5;
      position: relative;
    }
    .on:before {
      content: "";
      position: absolute;
      left: 48%;
      top: 100%;
      width: 0;
      height: 0;
      border-top: 8px solid #0c7fe5;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    .list {
      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        filter: grayscale(100%);
      }
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 90px;
      cursor: pointer;
    }
  }
}
.modular-2 {
  .list:hover {
    .bottom {
      background: #166dba;
      transition: all 1s;
      .name,
      .text {
        color: #ffffff !important;
        transition: all 0.5s;
      }
    }
    .el-image {
      transform: scale(1.3);
      transition: all 1.8s;
    }
  }
  .bottom {
    .text {
      font-size: 14px;
      color: #333333;
      width: 293px;
    }
    .name {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      margin-bottom: 20px;
      width: 293px;
    }
    background: #f7f8fa;
    width: 100%;
    height: 190px;
    padding: 23px 30px;
  }
  .top {
    .el-image {
      width: 100%;
      height: 100%;
    }
    width: 100%;
    height: 260px;
    overflow: hidden;
  }
  .list {
    width: 350px;
    height: 460px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: -1px 0px 9.2px 0.08px rgba(0, 0, 0, 0.1);
  }
  .t2 {
    color: #666666 !important;
  }
  background: #f9f9f9;
}
.modular-1 {
  img {
    width: 1231px;
    height: 425px;
    display: block;
    margin: 110px auto;
    animation: floatY 2000ms ease-in-out infinite both;
  }
  @keyframes floatY {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }
  background: white;
}
.modular {
  .title-on {
    .t3 {
      width: 30px;
      height: 4px;
      background: #cae6ff;
      margin: auto;
    }
    .t2 {
      font-size: 16px;
      color: #dddddd;
      margin: 10px 0 15px;
    }
    .t1 {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    text-align: center;
    margin-bottom: 40px;
  }
  padding: 50px 0;
  width: 100%;
  background-position: center center;
}
.banner {
  .view {
    .text {
      font-size: 16px;
      line-height: 27px;
      width: 576px;
    }
    .henxin {
      width: 70px;
      height: 1px;
      background: rgba(255, 255, 255, 0.8);
      margin: 20px 0;
    }
    .title {
      font-size: 30px;
      font-weight: 500;
    }
    width: 580px;
    color: #ffffff;
    float: right;
  }
  .banner-view {
    position: absolute;
    width: 48%;
    top: 75px;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    padding: 31px 19px;
  }
  .decoration {
    width: 582px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 102%;
    z-index: 10;
    background: url(../../images/home/programme/diao.png) no-repeat;
    background-size: cover;
  }
  width: 100%;
  height: 460px;
  background: url(../../images/home/technology/p8.png) no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.content {
  width: 1200px;
  margin: auto;
}
.container {
  overflow: hidden;
  width: 100%;
}
</style>
